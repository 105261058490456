<template>
	<div class="header">{{ org.title }}</div>
	<div class="body">
		<div class="fields">
			<div>Title</div>
			<div><input type="text" v-model="org.title" /></div>
			<div>Phone</div>
			<div><input type="text" v-model="org.phone" /></div>
			<div>Seats</div>
			<div>
				<input v-model="org.seats" type="number" :min="org.openClinicians + org.awaitingRSVPs" />
			</div>
			<div>pipedriveId</div>
			<div><input v-model="org.crmId" type="number" placeholder="[null]" /></div>
			<div>billingContactId</div>
			<div><input v-model="org.billingContactId" type="number" placeholder="[null]" /></div>
		</div>
	</div>
	<div class="footer">
		<button @click="close">Close</button>
		<Richbutton :theme="canSave ? 'prim' : ''" :working="updating" :disabled="!canSave" @click="save">
			Save
		</Richbutton>
	</div>
</template>

<script>
	import { cloneDeep, pick } from "lodash"
	import { ref, computed, onMounted } from "vue"
	import { alertsList, orgs } from "@/store"
	import { Richbutton } from "@/components"

	export default {
		name: "EditOrg",
		components: { Richbutton },
		props: {
			id: { type: Number, required: true }
		},
		emits: ["close"],
		setup(props, cntxt) {
			const alerts = alertsList()
			const orgStore = orgs()
			const org = ref({})
			const saved = computed(() => orgStore.orgs.value[props.id])
			const canSave = computed(() => JSON.stringify(org.value) !== JSON.stringify(saved.value))
			const close = (result) => cntxt.emit("close", result)
			const save = async () => {
				const payload = {
					...pick(org.value, ["title", "phone", "seats"]),
					crmId: +org.value.crmId || null,
					billingContactId: org.value.billingContactId || null
				}
				try {
					const result = await orgStore.updateOrg(org.value.id, payload)
					close(result)
				} catch (err) {
					org.value = cloneDeep(saved.value)
				}
			}

			onMounted(() => {
				org.value = cloneDeep(saved.value)
			})

			return {
				...orgStore,
				org,
				save,
				canSave,
				close
			}
		}
	}
</script>

<style lang="scss"></style>

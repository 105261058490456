<template>
	<div class="header">Choose Team</div>
	<div class="chooseTeam body">
		<vSelect
			v-model="selectedTeam"
			@search="(v) => (teamSearch = v)"
			:options="teamList"
			placeholder="Team..."
			label="label"
			class="ba"
		>
			<template #option="option">
				<span
					><span class="optClinicName">[{{ option.orgTitle }}]</span> {{ option.title }}</span
				>
			</template>
			<template #selected-option="{ title, orgTitle }">{{ title }} [{{ orgTitle }}]</template>
		</vSelect>
	</div>
	<div class="footer">
		<button @click="$emit('close')">Close</button>
		<button v-if="selectedTeam" @click="$emit('close', selectedTeam)" class="bgGrdPos">Next</button>
	</div>
</template>

<script>
	import { debounce } from "lodash"
	import { ref, computed, watch } from "vue"
	import { teams } from "@/store"
	import vSelect from "vue-select"

	export default {
		name: "ChooseTeam",
		components: { vSelect },
		emits: ["close"],
		props: {
			orgId: { type: Number }
		},
		setup(props) {
			const teamStore = teams()
			const teamSearch = ref("")
			const teamIds = ref([])
			const selectedTeam = ref(null)
			const teamList = computed(() => teamIds.value.map((id) => teamStore.teams.value[id]))
			const fetchTeams = debounce(async (search) => {
				const filter = { search: search || "" }
				if (props.orgId) filter.orgId = props.orgId
				console.log(filter)
				const result = await teamStore.fetchTeams(filter)
				if (result) teamIds.value = result.ids
			}, 500)
			watch(teamSearch, fetchTeams)
			fetchTeams()

			return {
				selectedTeam,
				teamSearch,
				teamList
			}
		}
	}
</script>

<style lang="scss">
	.chooseTeam.body {
		z-index: 15;
	}
</style>

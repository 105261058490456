<template>
	<div class="organizations">
		<div class="flx gap-2">
			<Richbutton v-if="!orgId" :working="creating" theme="pos" @click="createOrg">New</Richbutton>
			<input type="search" v-model="search" placeholder="Filter Organizations..." class="search flx-1 ba" />
			<Pagination :totalPages="totalPages" :page="paged" @click="setPage" class="flx-0" />
		</div>
		<DataTable :items="orgList" class="bh bt mt-5">
			<template v-slot:thead>
				<tr>
					<th>id</th>
					<th class="stickLeft">Organizations (Total: {{ total }})</th>
					<th>Type</th>
					<th>State</th>
					<th>Systems</th>
					<th colspan="3">
						<div class="txt aln-cntr nowrap">Seats</div>
						<div class="flx gap-2">
							<div class="flx-1 txt aln-lft">Total</div>
							<div class="flx-1 txt aln-cntr nowrap mh-2">In Use</div>
							<div class="flx-1 txt aln-rgt">Free</div>
						</div>
					</th>
					<th>Managers</th>
					<th colspan="2">
						<div class="txt aln-cntr nowrap">Clinicians</div>
						<div class="flx gap-2">
							<div class="flx-1 txt aln-lft">Total</div>
							<div class="flx-1 txt aln-rgt">Enabled</div>
						</div>
					</th>
					<th colspan="4">
						<div class="txt aln-cntr nowrap">30 Day / 90 Day</div>
						<div class="flx gap-2">
							<div class="flx-1 txt aln-lft">Users</div>
							<div class="flx-1 txt aln-cntr">Logins</div>
							<div class="flx-1 txt aln-cntr">Prints</div>
							<div class="flx-1 txt aln-rgt">Sends</div>
						</div>
					</th>
					<!--

					<th>Provided by</th>
					<th>Organization</th>
					<th>Last Login</th>
					<th colspan="3">
						<div class="txt aln-cntr nowrap">30 Day / 90 Day</div>
						<div class="flx">
							<div class="flx-1 txt aln-lft">Logins</div>
							<div class="flx-1 txt aln-cntr">Prints</div>
							<div class="flx-1 txt aln-rgt">Sends</div>
						</div>
					</th>
-->
					<th>Created</th>
				</tr>
			</template>
			<template v-slot:tbody="{ item }">
				<tr
					@click="(e) => showContextMenu(item.id, e)"
					:class="{ selected: selectedOrg.id === item.id, disabled: item.closed }"
				>
					<td class="ts" @click.stop>
						<div class="flx jst-cntr">
							<Spinner v-if="updating === item.id" />
							<template v-else>{{ item.id }}</template>
						</div>
					</td>
					<td class="stickLeft">
						<div class="title">{{ item.title }}</div>
						<div>{{ item.phone }}</div>
					</td>
					<td>
						<div v-if="item.selfServe" class="selfServe">Self-Serve</div>
						<div v-else>Manual</div>
						<div v-if="item.clientPremium" class="tag bgGrdWarn">Client Premium</div>
						<div v-if="item.trialDaysLeft" class="trial flx aln-cntr txt nowrap">
							Trial ends: {{ item.trialDaysLeft }} day<template v-if="item.trialDaysLeft !== 1"
								>s</template
							><button class="x ml-2" @click.stop="updateAttr(item.id, 'trialUntil', null)" />
						</div>
					</td>
					<td>
						<div v-if="item.closed" class="closed">Closed</div>
						<div v-else class="open">Open</div>
					</td>
					<td @click.stop>
						<div class="flx alg-cntr gap-1">
							<a
								v-if="item.crmId"
								:href="`https://simpleset.pipedrive.com/organization/${item.crmId}`"
								target="_blank"
								class="tag bgGrdAcc"
								@click.stop
								>PD</a
							>
							<a
								v-if="item.billingId"
								:href="`https://${cbDomain}.chargebee.com/d/customers/${item.billingId}`"
								target="_blank"
								class="tag bgGrdPos"
								@click.stop
								>CB</a
							>
						</div>
					</td>
					<td class="ts txt aln-cntr">
						<span v-if="item.seats === null" class="warn">NA</span>
						<template v-else>{{ item.seats }}</template>
					</td>
					<td class="ts txt aln-cntr">{{ item.openClinicians + item.awaitingRSVPs }}</td>
					<td class="ts txt aln-cntr">
						<span v-if="item.seats === null" class="warn">NA</span>
						<template v-else>{{ item.seats - (item.openClinicians + item.awaitingRSVPs) }}</template>
					</td>
					<td @click.stop>
						<a
							v-for="(user, index) in item.managers"
							:key="index"
							href="#"
							@click.prevent="router.push({ name: 'Users', params: { id: user.id } })"
							class="manager"
						>
							{{ user.lastname }}, {{ user.firstname }}
						</a>
					</td>
					<td class="ts txt aln-cntr">
						{{ item.totalClinicians }}
					</td>
					<td class="ts txt aln-cntr">
						{{ item.openClinicians }}
					</td>
					<td class="ts txt aln-cntr">{{ item.cntActiveClinicians1 }} / {{ item.cntActiveClinicians3 }}</td>
					<td class="ts txt aln-cntr">{{ item.cntLogins1 }} / {{ item.cntLogins3 }}</td>
					<td class="ts txt aln-cntr">{{ item.cntPrints1 }} / {{ item.cntPrints3 }}</td>
					<td class="ts txt aln-cntr">{{ item.cntSends1 }} / {{ item.cntSends3 }}</td>

					<!--

					<td>
						{{ item.designedBy }}
						<div
							v-if="item.clinician && item.designedBy !== `${item.firstname} ${item.lastname}`"
							class="mismatch"
						>
							Mismatch
						</div>
					</td>
					<td @click.stop="setOrgId(item.orgId)" class="orgCol">
						<div class="orgTitle">
							{{ item.orgTitle }}
						</div>
						<div v-for="(team, i) in item.teams" :key="i" class="team">{{ team.title }}</div>
					</td>
					<td class="ts">{{ item.lastLogin ? item.lastLogin.split("T")[0] : "-" }}</td>
					<td class="ts">{{ item.cntLogins1 }} / {{ item.cntLogins3 }}</td>
					<td class="ts">{{ item.cntPrints1 }} / {{ item.cntPrints3 }}</td>
					<td class="ts">{{ item.cntSends1 }} / {{ item.cntSends3 }}</td>
-->

					<td class="ts">{{ item.created.split("T")[0] }}</td>
				</tr>
			</template>
			<template v-slot:noResults> No results </template>
			<template v-slot:tfoot>
				<div class="flx" v-if="orgId">
					<button class="ml-a mr-a mt-5 ph-6 bgGrdAcc" @click="backToList">Show all Organizations...</button>
				</div>
			</template>
		</DataTable>

		<Transition name="slideDown">
			<ContextMenu v-if="contextMenu" :pos="contextMenu" @close="contextMenu = false">
				<header>{{ selectedOrg.title }}</header>
				<button @click="showEdit(selectedOrg.id)">Edit</button>
				<button v-if="selectedOrg.closed" @click="updateAttr(selectedOrg.id, 'closed', false)">Reopen</button>
				<button v-else @click="updateAttr(selectedOrg.id, 'closed', true)">Close</button>
				<button @click="router.push({ name: 'Users', query: { orgId: selectedOrg.id } })">View Users</button>
				<button @click="router.push({ name: 'Clients', query: { orgId: selectedOrg.id } })">
					View Clients
				</button>
				<button @click="router.push({ name: 'Folders', query: { orgId: selectedOrg.id } })">
					View Folders
				</button>
				<button v-if="selectedOrg.selfServe" @click="updateAttr(selectedOrg.id, 'selfServe', false)">
					Convert to Manual
				</button>
				<button
					v-else-if="selectedOrg.seats <= 15 && selectedOrg.billingId"
					@click="convertSelfServe(selectedOrg.id)"
				>
					Convert to Self-Serve
				</button>
				<button v-if="!selectedOrg.clientPremium" @click="updateAttr(selectedOrg.id, 'clientPremium', true)">
					Set Client Premium
				</button>
				<button v-else @click="updateAttr(selectedOrg.id, 'clientPremium', false)">Unset Client Premium</button>
				<button v-if="!selectedOrg.closed && selectedOrg.selfServe" @click="setTrial(selectedOrg.id)">
					Set Trial Until
				</button>
				<button @click="moveTeam(selectedOrg)">Move Team</button>
				<button v-if="!selectedOrg.selfServe" @click="mergeOrgs(selectedOrg)">Merge</button>
				<button v-if="!selectedOrg.totalClinicians" @click="delOrg(selectedOrg)">Delete</button>
			</ContextMenu>
		</Transition>
	</div>
</template>

<script>
	import { debounce } from "lodash"
	import { ref, computed, watch, onMounted } from "vue"
	import { useRouter, useRoute } from "vue-router"
	import { api, alertsList, modals, orgs } from "@/store"
	import { ContextMenu, DataTable, Pagination, Richbutton, Spinner } from "@/components"
	import ChooseDate from "../ChooseDate"
	import ChooseTeam from "../ChooseTeam"
	import ChooseOrg from "../ChooseOrg"
	import EditOrg from "./EditOrg"

	export default {
		name: "OrgList",
		components: { ContextMenu, DataTable, Pagination, Richbutton, Spinner },
		props: {},
		setup(props) {
			const router = useRouter()
			const route = useRoute()
			const modalStore = modals()

			// orgList
			const orgStore = orgs()
			const total = ref(0)
			const page = computed(() => +route.query.page || 1)
			const paged = ref(1)
			const perPage = ref(50)
			const totalPages = computed(() => Math.ceil(total.value / perPage.value))
			const orgIds = ref([])
			const orgList = computed(() => orgIds.value.map((id) => orgStore.orgs.value[id]).filter((v) => v))
			const orgId = computed(() => +route.params.id || null)
			const search = ref("")
			const searched = ref("")
			const selectedOrg = ref({})
			const cbDomain = ref("")
			const setPage = (v) => router.push({ query: { page: v }, name: "Organizations" })
			const fetchOrgs = async (p) => {
				if (orgId.value) {
					findOrgById(orgId.value)
				} else {
					const filter = { page: p || page.value }
					if (search.value) filter.search = search.value
					const result = await orgStore.fetchOrgs(filter)
					if (result) {
						searched.value = result.search
						total.value = result.total
						orgIds.value = result.ids
						paged.value = result.page
						if (page.value !== paged.value) setPage(paged.value)
					}
				}
			}
			const debouncedFetchOrgs = debounce(fetchOrgs, 500)
			const findOrgById = async (orgId) => {
				if (!orgStore.orgs.value[orgId]) await orgStore.fetchOrg(orgId)
				orgIds.value = [orgId]
				selectedOrg.value = orgStore.orgs.value[orgId]
			}
			const backToList = () => {
				fetchOrgs(1)
				router.push({ name: "Organizations" })
			}
			watch(search, () => {
				if (orgId.value) router.push({ name: "Organizations" })
				debouncedFetchOrgs(1)
			})
			watch(page, () => {
				if (page.value !== paged.value) {
					fetchOrgs()
				}
			})
			watch(orgId, (v) => {
				if (v) findOrgById(v)
				else fetchOrgs()
			})

			// contextMenu
			const contextMenu = ref(false)
			const showContextMenu = (userId, { clientX, clientY }) => {
				selectedOrg.value = orgStore.orgs.value[userId]
				contextMenu.value = { x: clientX, y: clientY }
			}

			// actions
			const alerts = alertsList()
			const updateAttr = (orgId, attr, val) =>
				orgStore.updateOrg(orgId, { [attr]: val }).then((result) => {
					alerts.push("Done", "pos")
					return result
				})
			const showEdit = (orgId) => {
				modalStore.open.component({
					component: EditOrg,
					props: { id: orgId },
					size: "sm"
				})
			}
			const setTrial = async (orgId) => {
				const date = await modalStore.open.component({
					component: ChooseDate,
					size: "sm"
				})
				if (date) updateAttr(orgId, "trialUntil", date)
			}
			const convertSelfServe = async (orgId) => {
				const confirm = await modalStore.open.dialog({
					header: "Convert to Self-Serve",
					body: `
						<b>Note:</b><br /><br />
						Chargebee subscription must be compatible with self-serve.<br />
						Max 15 seats<br />
						Unit-based pricing<br />
					`,
					buttons: [
						{
							label: "Cancel"
						},
						{
							label: "Confirm"
						}
					]
				})
				if (confirm) updateAttr(orgId, "selfServe", true)
			}
			const moveTeam = async (org) => {
				const team = await modalStore.open.component({
					component: ChooseTeam,
					props: { orgId: org.id },
					size: "sm"
				})
				if (!team) return
				const targetOrg = await modalStore.open.component({
					component: ChooseOrg,
					size: "sm"
				})
				if (!targetOrg || targetOrg.id === org.id) return
				const confirm = await modalStore.open.dialog({
					header: "Confirm Move Team",
					body: `
						You are about to move the following:<br /><br />
						<b>Team:</b> ${team.title}<br />
						<b>From Organization:</b> ${org.title}<br />
						<b>To Organization:</b> ${targetOrg.title}<br /><br />
						<b>Note:</b><br />
						Team can only be moved if all conditions are true:<br />
						Clinicians on Team are not joined to any other Teams<br />
						Clients on Team are not joined to any other Teams<br />
						New Organization must have enough Seats<br />
					`,
					buttons: [
						{
							label: "Cancel"
						},
						{
							label: "Confirm"
						}
					]
				})
				if (confirm) {
					const success = await api.put(`admin/teams/${team.id}/organization/${targetOrg.id}`)
					if (success) {
						alerts.push("Team moved", "pos")
						fetchOrgs()
					}
				}
			}
			const mergeOrgs = async (org) => {
				const targetOrg = await modalStore.open.component({
					component: ChooseOrg,
					size: "sm"
				})
				if (!targetOrg || targetOrg.id === org.id) return
				const confirm = await modalStore.open.dialog({
					header: "Confirm Merge Organization",
					body: `
						You are about to merge two Organizations:<br /><br />
						<b>Organization 1:</b> ${org.title}<br />
						<b>Organization 2:</b> ${targetOrg.title}<br /><br />
						<b>Note:</b><br />
						All Teams, Clinicians and Clients from <b>${targetOrg.title}</b> will be merged into <b>${org.title}</b><br />
						Exercise, Template, Education and Outcome ORGANIZATION folders are NOT transferred from <b>${targetOrg.title}</b> automatically<br />
						<b>${targetOrg.title}</b> is NOT automaticaly deleted
					`,
					buttons: [
						{
							label: "Cancel"
						},
						{
							label: "Confirm"
						}
					]
				})
				if (confirm) {
					const merged = await api.put(`admin/organizations/${org.id}/merge/${targetOrg.id}`)
					if (merged) {
						alerts.push("Organizations merged", "pos")
						fetchOrgs()
					}
				}
			}
			const createOrg = async () => {
				const success = await orgStore.createOrg()
				if (success) {
					alerts.push("Created", "pos")
					fetchOrgs(1)
				}
			}
			const delOrg = async (org) => {
				const confirm = await modalStore.open.dialog({
					header: "Confirm Delete Organization",
					body: `
						You are about to delete the following Organization:<br /><br />
						<b>Organization:</b> ${org.title}<br /><br />
						<b>Note:</b><br />
						Organizatons can only be deleted if all conditions are true:<br />
						No Teams<br />
						No Clinicians<br />
						No content in Organization Folders (Exercises / Templates / Education / Outcomes)<br />
					`,
					buttons: [
						{
							label: "Cancel"
						},
						{
							label: "Confirm"
						}
					]
				})
				if (confirm) {
					const orgId = await orgStore.deleteOrg(org.id)
					if (orgId) {
						orgIds.value = orgIds.value.filter((id) => id !== orgId)
						alerts.push("Deleted", "pos")
					}
				}
			}

			onMounted(() => {
				cbDomain.value = window.location.hostname.includes("dev") ? "simpleset-test" : "simpleset"
				if (route.params.id) findOrgById(route.params.id)
				else fetchOrgs()
			})

			return {
				router,
				...orgStore,
				total,
				page,
				paged,
				perPage,
				totalPages,
				orgId,
				orgList,
				search,
				searched,
				selectedOrg,
				fetchOrgs,
				setPage,
				backToList,
				cbDomain,

				contextMenu,
				showContextMenu,

				updateAttr,
				showEdit,
				createOrg,
				setTrial,
				convertSelfServe,
				moveTeam,
				mergeOrgs,
				delOrg
			}
		}
	}
</script>

<style lang="scss">
	.organizations {
		display: grid;
		grid-template-rows: auto 1fr;
		.dataTable {
			th.stickLeft:before {
				content: "";
				display: block;
				width: 160px;
			}
			tr.disabled {
				div,
				td,
				span {
					color: $clr-neg !important;
					background: $clr-neut-l2 !important;
				}
			}
			.spinner {
				width: 18px;
				height: 18px;
			}
			.title {
				color: $clr-prim;
				font-weight: bold;
			}
			.trial,
			.warn {
				font-weight: bold;
				color: $clr-warn;
				font-size: $fnt-sml;
			}
			.closed {
				font-weight: bold;
				color: $clr-neg;
			}
			.open {
				color: $clr-pos;
			}
			.manager {
				display: block;
			}
			.selfServe {
				color: $clr-prim;
				font-weight: bold;
			}
		}
	}
</style>
